import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-media-thumb',
  host: {'class': 'd-block'},
  template: `
    <div class="media-thumb" (click)="this.onSelect.emit(); $event.stopPropagation()" [ngClass]="{'isActive': isActive }">
      <media-preview class="media-thumb-preview" class [media]="media"></media-preview>
      <div class="media-thumb-description">{{ media.name }}</div>

      <div class="media-thumb-actions px-2">
        <ng-template #popContent>
          <div class="alert alert-warning">
            <p class="m-0">
              <span class="warning-icon"><i class="fa fa-exclamation-circle"></i></span> Delete this
              media!
            </p>
          </div>
          <button class="btn btn-danger btn-sm btn-block" (click)="this.onRemove.emit(); $event.stopPropagation()">Delete</button>
        </ng-template>
        <!-- [popoverTitle]="popTitle" -->
        <i
          class="fa fa-lg fa-trash-o pointer"
          [ngbPopover]="popContent"
          container="body"
          aria-hidden="true"
          (click)="$event.stopPropagation()"
        ></i>
      </div>
    </div>
  `
})
export class MediaThumbComponent {
  @Input() isActive: boolean;
  @Input() media: any;
  @Output() onSelect = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  constructor() {}
}
