import { Injectable } from '@angular/core';
import { RestangularModule, Restangular } from 'ngx-restangular';
import 'rxjs/add/operator/toPromise';
import { AppService } from '../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEnvironment } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(
    private restangular: Restangular,
    private appService: AppService,
    private http: HttpClient
  ) {}

  search(params: any): Promise<any> {
    return this.restangular.one('v1/media', 'search').get(params).toPromise();
  }

  upload(base64: String, options: any): Promise<any> {
    return this.restangular
      .all('v1/medias')
      .post(
        Object.assign(options, {
          base64
        })
      )
      .toPromise();
  }

  uploadRemoteDocument(payload: {
    url: string;
    name: string;
    description: string;
  }) {
    const formModel = new FormData();
    
    formModel.append('url', payload.url);
    formModel.append('type', 'link');
    formModel.append('name', payload.name);
    formModel.append('description', payload.description);

    const config = this.appService.settings as IEnvironment;

    let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
      headers = headers.append('Accept-Language', 'en');

    return this.http.post(`${config.apiBaseUrl}/v1/api/medias`, formModel, {headers}).toPromise();
}

  update(id: String, params: any): Promise<any> {
    return this.restangular.one('v1/media', id).customPUT(params).toPromise();
  }

  remove(mediaId: string): Promise<any> {
    return this.restangular.one('v1/media', mediaId).customDELETE().toPromise();
  }
}
