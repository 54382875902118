import { Component, OnInit, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../shared/services';
import { MediaService } from '../service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.html'
})
export class MediaModalComponent implements OnInit, AfterViewInit {
  @Input() options: any;
  public uploadedFiles = [];
  public tab: string = 'library';
  public files = [];
  public page: any = 1;
  public totalMedia = 0;
  public hasBaseDropZoneOver: Boolean = false;
  public filesQueue: any = [];
  public fileSelectOptions: any;
  public imageBase64: any = '';
  public croppedImage: any = '';
  public keyword: any = {
    name: '',
    description: ''
  };
  public loading: Boolean = false;

  private croppedFile: any;
  public activeEditMedia: any;
  public submitted: Boolean = false;

  public remoteFormSubmitted = false;
  public remoteMediaForm = {
    url: 'https://youtu.be/2V4UQzjl_0E?si=kMxNuE1Ud92PFyxZ',
    name: '',
    description: '',
  }

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private mediaService: MediaService,
    private toasty: ToastrService,
    private appService: AppService,
  ) {}
  ngOnInit() {
    if (!this.options) {
      this.options = {};
    }

    this.fileSelectOptions = Object.assign(this.options, {
      url: this.appService.settings.apiBaseUrl + '/v1/api/medias',
      uploadOnSelect: true,
      onCompleteItem: resp => {
        this.uploadedFiles.push(resp.data)
      },
      onFinish: resp => {
        this.toasty.success('File has been uploaded');
        this.changeTab('library');
      },
      onFileSelect: this.onFileSelect.bind(this)
    });
  }

  ngAfterViewInit() {
    this.loadLibrary();
  }

  getPreview(file: any) {
    // if (file.type) {
    //   // do nothing if it already set
    //   return;
    // }

    if (file.file.type.indexOf('image') > -1) {
      // file.type = 'photo';

      const reader = new FileReader();
      reader.onload = (e: any) => (file.previewContent = e.target.result);

      // read the image file as a data URL.
      reader.readAsDataURL(file._file);
    }
    // else if (file.file.type.indexOf('video') > -1) {
    //   file.type = 'video';
    // } else {
    //   file.type = 'file';
    // }
  }

  onFileSelect(queue) {
    const _this = this;
    this.filesQueue = queue;
    this.filesQueue.forEach((q: any) => _this.getPreview(q));
  }

  remove(item: any) {
    this.fileSelectOptions.uploader.removeFromQueue(item);
  }

  select(media: any) {
    this.selectToEdit(media);
    this.changeTab('library');
  }

  loadLibrary() {
    this.loading = true;
    this.mediaService
      .search(
        Object.assign(
          {
            page: this.page,
            take: 12
          },
          this.options && this.options.query ? this.options.query : {},
          this.keyword
        )
      )
      .then(resp => {
        this.files = resp.data.items;
        this.totalMedia = resp.data.count;
        this.loading = false;
      });
  }

  changeTab(tab) {
    if (this.tab !== 'library' && tab == 'library') {
      this.files = [];
      this.loadLibrary();
    }

    this.tab = tab;
  }

  imageCropped(image: String) {
    this.croppedImage = image;
  }

  selectCrop(image: any) {
    this.croppedFile = image;
    this.imageBase64 = image.previewContent;
  }

  crop() {
    this.croppedFile.uploadFileType = 'base64';
    this.croppedFile.previewContent = this.croppedImage;
    this.imageBase64 = null;

    // do upload for this file
    this.mediaService
      .upload(this.croppedImage, {
        name: this.croppedFile.file.name
      })
      .then(resp => {
        this.toasty.success('File has been cropped and uploaded');
        this.remove(this.croppedFile);
      })
      .catch(e => this.toasty.error(e.data.message || 'Have error, please try again'));
  }

  search() {
    this.page = 1;
    this.files = [];
    this.loadLibrary();
  }

  selectToEdit(media: any) {
    this.submitted = false;
    this.activeEditMedia = media;
  }

  update(frm: any) {
    this.submitted = true;

    if (frm.invalid) {
      return;
    }

    this.mediaService
      .update(this.activeEditMedia._id, {
        name: this.activeEditMedia.name,
        description: this.activeEditMedia.description
      })
      .then(resp => {
        this.toasty.success('Updated');
      })
      .catch(err => this.toasty.error(err.data.message || 'Something went wrong!'));
  }

  isImage(file: any): boolean {
    if (file.thumbUrl) {
      return true;
    }

    if (file?.mimeType.includes('image')) {
      return true;
    }

    return file.type === 'photo';
  }

  removeMedia(media: any, i: any) {
    this.mediaService
      .remove(media._id)
      .then(resp => {
        if (resp.data && resp.data.ok) {

          this.files.splice(i, 1);

          if (this.activeEditMedia._id === media._id) {
            this.activeEditMedia = null;
          }

          this.toasty.success('Removed media!');
        }
      })
      .catch(err =>
        this.toasty.error(
          (err.data.data && err?.data?.data?.details[0]?.message) || err.data.message || 'Somethings went wrong, please try again!'
        )
      );
  }

  submitRemoteMedia(frm: any) {
    if (frm.invalid) {
      return;
    }

    this.remoteFormSubmitted = true;

    this.mediaService.uploadRemoteDocument(this.remoteMediaForm)
      .then(response => {
        this.changeTab('library');
        this.toasty.success(
          'Successfully added a new remote media!'
        )
      })
  }

  submit() {
    this.activeModal.close(this.activeEditMedia);
  }
}

@Component({
  selector: 'app-media-select',
  template: `
    <small *ngIf="subTitle" class="text-muted mr-2">{{subTitle}}</small>
    <span class="pointer media-select-btn" (click)="open()"><i class="fa fa-folder-open"></i> Browse</span>
  `
})
export class FileSelectComponent {
  @Output() onSelect = new EventEmitter();
  /**
   * option format
   * {
   *  customFields: { key: value } // additional field will be added to the form
   *  query: { key: value } // custom query String
   * }
   */
  @Input() options: any;
  @Input() subTitle: string;
  constructor(private modalService: NgbModal) {}

  open() {
    const modalRef = this.modalService.open(MediaModalComponent, {
      size: 'lg',
      windowClass: 'modal-media'
    });

    modalRef.componentInstance.options = this.options;
    modalRef.result.then(
      result => this.onSelect.emit(result),
      () => null
    );
  }
}
