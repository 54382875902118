import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'media-preview',
  template: `
    <div class="media-preview">
      <img
        class="img-fluid img-thumbnail media-gallery-item"
        [src]="media?.fileUrl"
        alt=""
        *ngIf="isImage"
      />
      <i class="fa media-gallery-item img-thumbnail" *ngIf="!isImage" [ngClass]="customClass"></i>
      <div *ngIf="showCaption" class="media-preview-caption">
        <span class="media-preview-title">{{ media?.name }}</span>
      </div>
</div>
  `
})
export class MediaPreviewComponent implements OnInit {
  @Input() media: any;
  @Input() showCaption: boolean;

  public customClass = {};

  ngOnInit() {
    if (!this.media) {
      return;
    }

    if (this.media.type === 'video' || (this.media.mimeType && this.media.mimeType.indexOf('video') > -1)) {
      this.customClass = {
        'fa-video-camera': true
      };
    } else {
      this.customClass = {
        'fa-file': true
      };
    }
  }

  get isImage(): boolean {
    if (!this.media.type && !this.media?.mimeType) {
      return true;
    }

    if (this.media.thumbUrl) {
      return true;
    }

    if (this.media.type === 'photo') {
      return true;
    }

    return this.media?.mimeType.includes('image')
  }

}
